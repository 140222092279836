import ApiService from "./api.service";
const main = "getInfo";
export default {
    getList({ type, params, legalAidId, orderBaseId, year, search, status }) {
        return ApiService.post(
            `${main}/ownOrder?type=${type || ""}&legalAidId=${
                legalAidId || ""
            }&orderBaseId=${orderBaseId || ""}&year=${year || ""}&search=${
                search || ""
            }&status=${status || ""}`,
            params
        );
    },
    LegalAidList() {
        return ApiService.post(`${main}/directoryLegalAidList`, {
            page: 0,
            limit: 100,
        });
    },
    listOwnLegalAidType() {
        return ApiService.get(`${main}/listOwnLegalAidType`);
    },
    getEIjroParentOrgsGroupedByTypeList(data) {
        return ApiService.post(`eijro/get-parent-orgs-grouped-by-type`, data);
    },
    mailGetRegions() {
        return ApiService.get(`mail/getRegions`);
    },
    mailGetArea(id) {
        return ApiService.get(`mail/getArea/${id}`);
    },
    getEIjroChildOrgList(data) {
        return ApiService.post(`eijro/child-org-list`, data);
    },
    orderBaseList() {
        return ApiService.post(`${main}/orderBaseList?search=`, {
            page: 0,
            limit: 100,
        });
    },
    create({
        file,
        params,
        legalAidId,
        personPnfl,
        orderBaseId,
        approvedDate,
        contractDate,
        contragentInn,
        contragentName,
        personLastName,
        orderRegNumber,
        personFirstName,
        personParentName,
    }) {
        let form = new FormData();
        form.append("file", file);
        params = JSON.stringify(params);
        form.append("key", params);
        form.append("personPnfl", personPnfl);
        form.append("contragentInn", contragentInn);
        form.append("contragentName", contragentName);
        form.append("personLastName", personLastName);
        form.append("personFirstName", personFirstName);
        form.append("personParentName", personParentName);
        return ApiService.post(
            `${main}/orderCreate?contractDate=${
                contractDate || ""
            }&legalAidId=${
                legalAidId || ""
            }&orderRegNumber=${orderRegNumber}&orderBaseId=${
                orderBaseId || ""
            }&approvedDate=${approvedDate || ""}`,
            form
        );
    },
    infoFromMvd(body) {
        return ApiService.post(`${main}/infoFromMvd`, body);
    },
    fileDownload(body) {
        return ApiService.post(`getInfo/fileDownload`, body);
    },
    getByOrderIdAndLawyerPnfl(orderId) {
        return ApiService.get(
            `${main}/getByOrderIdAndLawyerPnfl?orderId=${orderId}`
        );
    },
    ownCriminalCaseNumberList(orderId) {
        return ApiService.get(
            `${main}/ownCriminalCaseNumberList?orderId=${orderId}`
        );
    },
    listByCriminalCaseNumber(orderId, criminalCaseNumber) {
        return ApiService.get(
            `${main}/listByCriminalCaseNumber?orderId=${orderId}&criminalCaseNumber=${criminalCaseNumber}`
        );
    },
    searchCriminal(body) {
        return ApiService.post(`getInfo/searchCriminal`, body);
    },
    viewPdf(body) {
        return ApiService.post(`convert/view-pdf`, body);
    },
    mergePdfFiles(body) {
        return ApiService.post(`pdfFiles/mergePdfFiles`, body);
    },
    pdfFilesGet(letterId) {
        return ApiService.get(`pdfFiles/getByLetterId/${letterId}`);
    },
    pdfFilesCreate(body) {
        return ApiService.post(`pdfFiles/create`, body);
    },
    pdfFilesDeleteById(fileId) {
        return ApiService.delete(`pdfFiles/deleteById/${fileId}`);
    },
    additionalList({ pagination, search, orderId }) {
        return ApiService.post(
            `additional/list?orderId=${orderId}&search=${search}`,
            pagination
        );
    },
    additionalCreate(body) {
        return ApiService.post(`additional/create`, body);
    },
    additionalFilesCreate(body) {
        return ApiService.post(`additionalFiles/create`, body);
    },
    additionalUpdate(comment, additionalId) {
        return ApiService.post(
            `additional/update?comment=${comment}&additionalId=${additionalId}`
        );
    },
    additionalDelete(fileId) {
        return ApiService.delete(`additional/delete/${fileId}`);
    },
    additionalFilesDelete(fileId) {
        return ApiService.delete(`additionalFiles/delete/${fileId}`);
    },
    cancelOrder(orderId) {
        return ApiService.post(`${main}/cancelOrder?orderId=${orderId || ""}`);
    },
    orderCount() {
        return ApiService.get(`${main}/orderCount`);
    },
    countEmployee() {
        return ApiService.get(`${main}/countEmployee`);
    },
    getListForRequest() {
        return ApiService.get(`${main}/listForRequest`);
    },
    createDraft({
        id,
        legalAidId,
        personPnfl,
        orderBaseId,
        contractDate,
        contragentInn,
        orderRegNumber,
        contragentName,
        personLastName,
        personFirstName,
        personParentName,
    }) {
        if (id) {
            return ApiService.post(`${main}/updateDraft`, {
                id,
                legalAidId,
                personPnfl,
                orderBaseId,
                contractDate,
                contragentInn,
                orderRegNumber,
                contragentName,
                personLastName,
                personFirstName,
                personParentName,
            });
        } else {
            return ApiService.post(
                `${main}/createDraft?contractDate=${
                    contractDate || ""
                }&legalAidId=${
                    legalAidId || ""
                }&orderRegNumber=${orderRegNumber}&personPnfl=${personPnfl}&contragentInn=${contragentInn}&contragentName=${contragentName}&personLastName=${personLastName}&personFirstName=${personFirstName}&personParentName=${personParentName}&orderBaseId=${
                    orderBaseId || ""
                }`
            );
        }
    },
    deleteDraft(orderId) {
        return ApiService.post(`${main}/deleteOrder?orderId=${orderId || ""}`);
    },
    getDtoByOrderId(orderId) {
        return ApiService.get(`${main}/dtoByOrderId?orderId=${orderId || ""}`);
    },
    getByOrderId(orderId) {
        return ApiService.post(`${main}/getByOrderId?orderId=${orderId || ""}`);
    },
    // Contragent orderlarini ko'rish
    getContragentOrder({
        type,
        params,
        legalAidId,
        orderBaseId,
        employeePnfl,
        year,
        search,
    }) {
        return ApiService.post(
            `${main}/employeeOrder?type=${type || ""}&legalAidId=${
                legalAidId || ""
            }&orderBaseId=${orderBaseId || ""}&search=${search || ""}&year=${
                year || ""
            }&employeePnfl=${employeePnfl || ""}`,
            params
        );
    },
    signatureDraft({ params, orderId }) {
        return ApiService.post(
            `${main}/signatureDraft?orderId=${orderId || ""}`,
            params
        );
    },
    orderAccess({ id, orderCheck, orderReason }) {
        return ApiService.post(
            `${main}/orderAccess?lawyerId=${id || ""}&orderCheck=${orderCheck}`,
            { orderReason }
        );
    },
    orderCreateCheck() {
        return ApiService.get(`${main}/orderCreateCheck`);
    },
};
