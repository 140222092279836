const state = () => {
    return {
        sidebarMenu: [
            {
                titleKey: "user.user",
                to: "/lawyer-profile/user-info",
                icon: require("@/assets/img/sidebar/user.svg"),
                views: ["USER", "NEW_DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "applicant",
                to: "/lawyer-profile/applicant",
                icon: require("@/assets/img/sidebar/users.svg"),
                views: ["USER", "LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "forPersonsWithDisabilities",
                to: "/lawyer-profile/my-appeals",
                icon: require("@/assets/img/sidebar/requests.svg"),
                views: ["USER", "DIRECTOR", "NEW_DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "forPersonsWithDisabilities",
                to: "/lawyer-profile/my-appeals/appeals",
                icon: require("@/assets/img/sidebar/requests.svg"),
                views: ["LAWYER", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "citizensInTheSocialSecurityRegister",
                to: "/lawyer-profile/my-applications",
                icon: require("@/assets/img/sidebar/requests.svg"),
                views: ["USER", "ADMIN"],
            },
            {
                titleKey: "announcement.menu",
                to: "/lawyer-profile/announcement",
                icon: require("@/assets/img/sidebar/requests.svg"),
                views: ["DIRECTOR"],
            },
            {
                titleKey: "sidebar.controlPanel",
                to: "/lawyer-profile/control-panel",
                icon: require("@/assets/img/sidebar/control-panel.svg"),
                views: ["LAWYER", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "sidebar.control",
                to: "/lawyer-profile/control",
                icon: require("@/assets/img/sidebar/contracts.svg"),
                views: ["NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "sidebar.requests",
                to: "/lawyer-profile/requests",
                icon: require("@/assets/img/sidebar/requests.svg"),
                views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "sidebar.contracts",
                to: "/lawyer-profile/contracts",
                icon: require("@/assets/img/sidebar/contracts.svg"),
                views: ["LAWYER", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "correspondence",
                to: "/lawyer-profile/letters",
                icon: require("@/assets/img/sidebar/file-text.svg"),
                views: ["LAWYER", "DIRECTOR", "ADMIN"],
                children: [
                    {
                        checkPinfl: true,
                        titleKey: "sidebar.incoming",
                        to: "/lawyer-profile/letters-and-documents-incoming",
                        icon: require("@/assets/img/sidebar/file-text.svg"),
                        views: ["LAWYER", "DIRECTOR", "ADMIN"],
                    },
                    {
                        checkPinfl: true,
                        titleKey: "sidebar.outgoing",
                        to: "/lawyer-profile/letters-and-documents/outgoing",
                        icon: require("@/assets/img/sidebar/file-text.svg"),
                        views: ["LAWYER", "DIRECTOR", "ADMIN"],
                    },
                    {
                        checkPinfl: true,
                        titleKey: "sidebar.draft",
                        to: "/lawyer-profile/letters-and-documents/draft",
                        icon: require("@/assets/img/sidebar/file-text.svg"),
                        views: ["LAWYER", "DIRECTOR", "ADMIN"],
                    },
                    {
                        checkPinfl: true,
                        titleKey: "sidebar.docTemplate",
                        to: "/lawyer-profile/letters/doc-template",
                        icon: require("@/assets/img/sidebar/requests.svg"),
                        views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                    },
                ],
            },
            {
                titleKey: "sidebar.orders",
                to: "/lawyer-profile/orders",
                icon: require("@/assets/img/sidebar/orders.svg"),
                views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                children: [
                    {
                        titleKey: "orders.personalOrders",
                        to: "/lawyer-profile/orders/list",
                        views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                    },
                    {
                        titleKey: "orders.contragentOrders",
                        to: "/lawyer-profile/orders/contragents",
                        views: ["NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                    },
                ],
            },
            {
                titleKey: "sidebar.lawyerApplication",
                to: "/application/list",
                icon: require("@/assets/img/sidebar/orders.svg"),
                views: ["USER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                children: [
                    {
                        titleKey: "sidebar.lawyerApplicationForm",
                        to: "/lawyer-profile/application/form",
                        icon: require("@/assets/img/sidebar/orders.svg"),
                        views: ["USER", "ADMIN"],
                    },
                    {
                        titleKey: "sidebar.lawyerApplicationIntern",
                        to: "/lawyer-profile/application/intern",
                        icon: require("@/assets/img/sidebar/orders.svg"),
                        views: ["NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                    },
                    {
                        titleKey: "sidebar.lawyerApplicationInternRestr",
                        to: "/lawyer-profile/application/intern-restr",
                        icon: require("@/assets/img/sidebar/orders.svg"),
                        views: ["NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
                    },
                    {
                        titleKey: "sidebar.lawyerApplicationList",
                        to: "/lawyer-profile/application/list",
                        views: ["USER", "ADMIN"],
                    },
                ],
            },

            {
                titleKey: "sidebar.queryLog",
                to: "/lawyer-profile/notifications",
                icon: require("@/assets/img/sidebar/file-text.svg"),
                views: ["LAWYER", "DIRECTOR", "ADMIN"],
            },
            {
                titleKey: "trainCertificate",
                to: "/lawyer-profile/yumom-certificate",
                icon: require("@/assets/img/sidebar/bookmark.svg"),
                views: ["LAWYER", "DIRECTOR", "ADMIN"],
            },
        ],
    };
};

const getters = {};

const actions = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
